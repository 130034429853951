import { NextPage } from 'next'
import { useContext } from 'react'
import styled, { ThemeContext } from 'styled-components'

import RoundedButton from 'components/RoundedButton'
import Spacer from 'components/Spacer'

const Container = styled.div`
  width: 100%;
  height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

const StyledText = styled.div`
  font-style: normal;
  font-weight: 300;
  font-size: 32px;
  line-height: 39px;
  text-align: center;
  color: ${props => props.theme.colors.black};
`

const _404Page: NextPage = () => {
  const theme = useContext(ThemeContext)

  return (
    <Container>
      <StyledText>Oops, this page was not found.</StyledText>
      <StyledText>Let’s go back to the homepage.</StyledText>
      <Spacer size={45} />
      <RoundedButton
        text="Go to Homepage"
        href="/"
        background={theme.colors.purple}
        color={theme.colors.white}
        radius={20}
        width={236}
      />
    </Container>
  )
}

export default _404Page
